.clients-table {
    tr td:last-child, tr td:nth-child(7) {
        width: 1%;
        white-space: nowrap;
    }

    .notes {
        cursor: pointer;
        width: auto;

        &:hover {
            outline: 1px dashed black;
        }

        textarea {
            width: 100%;
            padding: 0;
            margin: 0;
        }
    }
}