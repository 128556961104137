.note-type-info {
    background-color: white;
}

.note-type-billing {
    background-color: #D9EDF7;
}

.notes-table {
    tr td:last-child, tr td:nth-last-child(2) {
        width: 1%;
        white-space: nowrap;
    }
}